import React from 'react'
import { Box, Typography, Theme } from '@mui/material'
import IconButton from '@components/core/IconButton'
import Link from '@components/core/link'

export type ContentfulTeaserProps = BASE.Helpers.ModuleDefaultProps & {
  teaserText: string
  teaserTitle: string
  teaserLink: {
    fields: BASE.Contentful.IFields
  }
}

export default function ContentfulTeaser({
  teaserText,
  teaserTitle,
  teaserLink,
}: ContentfulTeaserProps) {
  return (
    <Box
      sx={(theme: Theme) => ({
        background: '#FFF',
        height: '166px',
        width: '676px',
        marginLeft: '30px',
        boxShadow: theme.boxShadows.teaser,
        borderRadius: '32px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '32px',
        [theme.breakpoints.down('lg')]: {
          alignItems: 'end',
          margin: '0',
          padding: '16px',
          width: 'auto',
          height: 'auto',
        },
      })}
    >
      <Box>
        <Link to={teaserLink.fields?.fullPath}>
          <Typography
            sx={(theme) => ({
              fontSize: '22px',
              fontWeight: 'bold',
              color: theme.palette.primary.main,
            })}
          >
            {teaserTitle}
          </Typography>
        </Link>
        <Typography sx={{ fontSize: '16px', lineHeight: '26px' }}>
          {teaserText}
        </Typography>
      </Box>
      <Link to={teaserLink.fields?.fullPath}>
        <IconButton
          name="ChevronRight"
          sx={(theme: Theme) => ({
            fill: '#FFF',
            [theme.breakpoints.down('lg')]: {
              paddingLeft: '12px',
            },
          })}
        />
      </Link>
    </Box>
  )
}
